//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import copyToClipboard from 'quasar/src/utils/copy-to-clipboard.js';;
import Base from '@/mixins/Base';
import ReferralCodeBox from '@/components/Invite/ReferralCodeBox';
export default {
  name: 'ReferralPopup',
  mixins: [Base],
  components: {
    'app-referral-code-box': ReferralCodeBox
  },
  data: function data() {
    return {
      isShowClipBoardNote: false
    };
  },
  computed: {
    facebookData: function facebookData() {
      return {
        appId: 218153873842278,
        redirectUri: encodeURIComponent(this.referralData.referralUrl),
        display: 'iframe',
        href: encodeURIComponent(this.referralData.referralUrl)
      };
    },
    referralData: function referralData() {
      return this.$store.getters['player/referralData'];
    },
    currencySymbol: function currencySymbol() {
      return this.$store.getters['player/profile'].currencySymbol;
    }
  },
  methods: {
    linkTo: function linkTo(_ref) {
      var page = _ref.page,
          url = _ref.url;
      return this.$store.getters['lang/linkTo']({
        page: page,
        params: url
      });
    },
    copyReferralLink: function copyReferralLink() {
      var _this = this;

      this.$store.ga.clickButton({
        location: 'invite_friends',
        buttonName: 'copy'
      });
      copyToClipboard(this.referralData.referralUrl).then(function () {
        _this.callClipboardNote();

        console.log('copyToClipboard success');
      }).catch(function () {
        console.log('copyToClipboard error');
      });
    },
    callClipboardNote: function callClipboardNote() {
      var notifyPosition = this.isDesktop ? 'bottom-right' : 'top';
      this.$q.notify({
        message: 'Copied to clipboard!',
        color: 'success',
        position: notifyPosition,
        timeout: 1500,
        classes: 'notificaton-box notificaton-box--success'
      });
    },
    shareOnFacebook: function shareOnFacebook() {
      this.$store.ga.clickButton({
        location: 'invite_friends',
        buttonName: 'share_facebook'
      });
      window.location.href = "https://www.facebook.com/dialog/share?app_id=".concat(this.facebookData.appId, "&display=").concat(this.facebookData.display, "&href=").concat(this.facebookData.href, "&redirect_uri=").concat(this.facebookData.redirectUri);
    }
  },
  mounted: function mounted() {
    var focusing = document.body.getEventListeners().focusin;

    if (focusing && focusing[0]) {
      document.body.removeEventListener('focusin', focusing[0].listener, focusing[0].useCapture);
    }
  }
};